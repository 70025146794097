.casas {
    display: flex;
    height: auto;
    margin-top: 30px;
}

.oficina {
    width: 33%;
    margin-top: 30px;
    border: 2px solid orange;
    margin: 20px;
    border-radius: 15px;
}

.esparcimiento {
    width: 33%;
    margin-top: 30px;
    /*background-color: #35347b;*/
    margin: 20px;
    border-radius: 15px;
    border: 2px solid orange;
}

.habitacional {
    width: 33%;
    margin-top: 30px;
    border: 2px solid orange;
    margin: 20px;
    border-radius: 15px;
}

.casas img {
    width: 200px;
    height: 200px;
    border-radius: 30px;
    border: #35347b 2px solid;
}

.casas h3 {
    font-size: 1.8rem;
    color: azure;
    background-color: #35347b;
    padding: 10px;
    text-shadow: 1px 1px 1px black;
}

.categorias {
    font-size: 3rem;
}

.casas h4 {
    color: #35347b;
}

.casas p {
    color:#35347b;
}

/* Media query para tablets */
@media (min-width: 768px) and (max-width: 1023px) {
    .casas {
        padding-left: 15%;
        display: block;
    }

    .oficina {
        width: 75%;
    }

    .esparcimiento {
        width: 75%;
    }

    .habitacional {
        width:75%;
    }
    
}


/* Media query para smartphones */
@media (min-width: 391px) and (max-width: 767px) {
    .casas {
        display: block;
    }

    .oficina {
        width: 90%;
    }

    .esparcimiento {
        width: 90%;
    }

    .habitacional {
        width: 90%;
    }


}

@media (max-width: 391px){
    .casas {
        display: block;
    }

    .oficina {
        width: 90%;
    }

    .esparcimiento {
        width: 90%;
    }

    .habitacional {
        width: 90%;
    }
}