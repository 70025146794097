.PieDePagina{
    height: 50px;
    background-color: black;
    overflow: hidden;
    display: block;
    width: 100vw;
}

.PieDePagina{
    color: white;
    padding: 30px;
    font-size: 1em;
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif ;
}

@media (max-width:767px) {
    .PieDePagina{
        width: 100%;
        display: block;
    }
}

@media (min-width:768px) and (max-width:1023px) {
    .PieDePagina{
        width: 100%;
        display: block;
    }
}