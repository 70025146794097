#home {
    width: 100vw;
}

.banner {
    height: 510px;
    background-image: url('https://i.postimg.cc/qv8MGxzK/Vagon-Banner.jpg');
    background-size: 100%;
    background-repeat: no-repeat;
}

.divisor {
    height: 150px;
    background-image: url('https://i.postimg.cc/TYBp0P0z/Linea-Corte.jpg');
    margin-top: -50px;
}

.divisor h1 {
    padding-top: 40px;
    font-size: 3rem;
    color: azure;
    text-shadow: black 1px 2px 2px;
}

.detailsmenu {
    padding: 50px;
    margin: 50px;
    height: 600px;
    width: 100vw;
    background-image: url('https://i.postimg.cc/cLX1j4B1/Foto2.jpg') ;
    background-repeat: no-repeat;
    display: block;
}

.detailsmenu img {
    margin-top: 50px;
    height: 400px;
}

.detailsmenu button {
    height: 50px;
    width: 300px;
    background-color:#35347b;
    margin-top: 550px;
    color: azure;
    font-size: 22px;
    box-shadow: black 3px 3px 10px;
    font-family: 'Changa One';
}

.detailsmenu button:hover{
    box-shadow: black 0px 0px 10px;
    background-color: #4e4da1;
}   

.mision {
    height: 150px;
    background-color: #35347b;
    margin-top: -30px;
    margin-left: 25px;
    margin-right: 25px;
    margin-bottom: 30px;
    /*font-family: 'Changa One', cursive;*/
}

.mision p {
    font-size: 1.75em;
    padding-top: 30px;
    color: azure;
    text-shadow: black 1px 1px 1px;
}

.bannerimages{
    display: flex;
    margin: 25px;
}

.bannerimages img {
    width: 25%;
    padding: 5px;
}

.frasemodulos{
    display: flex;
    padding: 20px;
}

.titfrase {
    width: 50%;
    text-align: right;
    font-size: 1.5rem;
    border-right: 2px solid;

    padding-right: 20px;
}

.frase {
    width: 50%;
    text-align: left;
    padding-left: 20px;
    font-size: 20px;
    padding-top: 25px;
}

.details {
    height: 550px;
    background-image: url('https://i.postimg.cc/bvpYVStB/vagondetails.jpg');
    background-size: 1360px;
    background-repeat: no-repeat;
    display: flex;
}

.partevacia{
    width: 60%;
}

.partedetalle {
    width: 40%;
    height: 541px;
    background-color: rgb(229, 238, 234);
    opacity: 0.8 ;
    display: block;
}

.partedetalle h3 {
    font-size: 1.5rem;
    padding-top: 60px;
}

.partedetalle ul {
    font-size: 1.1rem;
}

.videoexplicativo {
    display: flex;
    background-color: #F28B52;
    margin-top: 30px;
}

.videoexplicativo p {
    width: 55%;
    font-size: 2.1rem;
    padding-top: 30px;
    color: #f0f0f0;
    text-shadow: black 0px 0px 0.75px;
}

.videoexplicativo iframe {
    margin: 20px;
}

.end img {
    width: 200px;
    margin: 40px;
}


/* Media query para tablets */
@media (min-width: 768px) and (max-width: 1023px) {
    .banner {
        height: 225px;
        background-size: 150%;
    }
    .divisor {
        height: 100px;
    }
    
    .divisor h1 {
        padding-top: 30px;
        font-size: 1.5rem;
    }
    
    .detailsmenu {
        visibility: hidden;
        height: 0px;
    }
    
    .detailsmenu img {
        margin-top: 50px;
        height: 400px;
    }
    
    .detailsmenu button {
        visibility: hidden;
        height: 1px;
    }
    
    
    .mision {
        height: 300px;
        margin: 5%;
    }
    
    .mision p {
        font-size: 1.5em;
    }
    
    .bannerimages{
        display: flex;
        margin: 25px;
    }
    
    .bannerimages img {
        width: 24%;
        padding: 5px;
    }
    
    .frasemodulos{
        display: block;
        padding: 20px;
    }
    
    .titfrase {
        width: 90%;
        text-align: center;
        font-size: 1rem;
        border-right: 0px solid;
    
        padding-right: 20px;
    }
    
    .frase {
        width: 90%;
        text-align: center;
        padding: 10px;
        font-size: 20px;
    }
    
    .details {
        height: auto;
        background-image: url('https://i.postimg.cc/bvpYVStB/vagondetails.jpg');
        background-size: auto;
        background-repeat: no-repeat;
        display: flex;
    }
    
    .partevacia{
        width: 0%;
    }
    
    .partedetalle {
        width: 100%;
        height: auto;
    }
    
    .partedetalle h3 {
        font-size: 1.5rem;
        padding-top: 60px;
    }
    
    .partedetalle ul {
        font-size: 1.1rem;
    }
    
    .videoexplicativo {
        display: block;
        background-color: #F28B52;
        margin-top: 30px;
    }
    
    .videoexplicativo p {
        width: 90%;
        font-size: 1.8rem;
        padding-top: 30px;
        color: #f0f0f0;
        text-shadow: black 0px 0px 0.75px;
    }
    
    .videoexplicativo iframe {
        margin: 0px;
        width: 90%;
    }
    
}


/* Media query para smartphones */
@media (min-width: 391px) and (max-width: 767px) {
    .banner {
        height: 400px;
        background-size: 150%;
    }
    .divisor {
        height: 100px;
    }
    
    .divisor h1 {
        padding-top: 30px;
        font-size: 1.5rem;
    }
    
    .detailsmenu {
        visibility: hidden;
        height: 0px;
    }
    
    .detailsmenu img {
        margin-top: 50px;
        height: 400px;
    }
    
    .detailsmenu button {
        visibility: hidden;
        height: 1px;
    }
    
    
    .mision {
        height: auto;
        margin: 5%;
    }
    
    .mision p {
        font-size: 1.5em;
        padding-left: 2%;
        padding-right: 2%;
        padding-bottom: 30px;
    }
    
    .bannerimages{
        display: flex;
        margin: 25px;
    }
    
    .bannerimages img {
        width: 24%;
        padding: 2px;
    }
    
    .frasemodulos{
        display: block;
        padding: 20px;
    }
    
    .titfrase {
        width: 90%;
        text-align: center;
        font-size: 1rem;
        border-right: 0px solid;
    
        padding-right: 20px;
    }
    
    .frase {
        width: 90%;
        text-align: center;
        padding: 10px;
        font-size: 20px;
    }
    
    .details {
        height: 550px;
        background-image: url('https://i.postimg.cc/bvpYVStB/vagondetails.jpg');
        background-size:auto;
        background-repeat: no-repeat;
        display: flex;
    }
    
    .partevacia{
        width: 0%;
    }
    
    .partedetalle {
        width: 100%;
        height: auto;
        display: block;
    }
    
    .partedetalle h3 {
        font-size: 1.5rem;
        padding-top: 60px;
        padding-left: 5%;
    }
    
    .partedetalle ul {
        font-size: 1.3rem;
    }
    
    .videoexplicativo {
        display: block;
        background-color: #F28B52;
        margin-top: 30px;
    }
    
    .videoexplicativo p {
        width: 90%;
        font-size: 1.8rem;
        padding-top: 30px;
        padding-left: 5%;
    }
    
    .videoexplicativo iframe {
        margin: 0px;
        width: 90%;
    }


}

@media (max-width: 391px){
    .banner {
        height: 400px;
        background-size: 150%;
    }
    .divisor {
        height: 100px;
    }
    
    .divisor h1 {
        padding-top: 30px;
        font-size: 1.5rem;
    }
    
    .detailsmenu {
        visibility: hidden;
        height: 0px;
    }
    
    .detailsmenu img {
        margin-top: 50px;
        height: 400px;
    }
    
    .detailsmenu button {
        visibility: hidden;
        height: 1px;
    }
    
    
    .mision {
        height: auto;
        margin: 5%;
    }
    
    .mision p {
        font-size: 1.5em;
        padding-left: 2%;
        padding-right: 2%;
        padding-bottom: 30px;
    }
    
    .bannerimages{
        display: flex;
        margin: 25px;
    }
    
    .bannerimages img {
        width: 24%;
        padding: 2px;
    }
    
    .frasemodulos{
        display: block;
        padding: 20px;
    }
    
    .titfrase {
        width: 90%;
        text-align: center;
        font-size: 1rem;
        border-right: 0px solid;
    
        padding-right: 20px;
    }
    
    .frase {
        width: 90%;
        text-align: center;
        padding: 10px;
        font-size: 20px;
    }
    
    .details {
        height: 550px;
        background-image: url('https://i.postimg.cc/bvpYVStB/vagondetails.jpg');
        background-size:auto;
        background-repeat: no-repeat;
        display: flex;
    }
    
    .partevacia{
        width: 0%;
    }
    
    .partedetalle {
        width: 100%;
        height: auto;
        display: block;
    }
    
    .partedetalle h3 {
        font-size: 1.5rem;
        padding-top: 60px;
        padding-left: 5%;
    }
    
    .partedetalle ul {
        font-size: 1.3rem;
    }
    
    .videoexplicativo {
        display: block;
        background-color: #F28B52;
        margin-top: 30px;
    }
    
    .videoexplicativo p {
        width: 90%;
        font-size: 1.8rem;
        padding-top: 30px;
        padding-left: 5%;
    }
    
    .videoexplicativo iframe {
        margin: 0px;
        width: 90%;
    }
    

}