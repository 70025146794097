#navigation{
    width: 100vw;
    display: flex;
    background: rgb(255,248,238);
    background: linear-gradient(90deg, rgba(255,248,238,1) 0%, rgb(249, 243, 240) 37%);
    border-bottom: 3px solid orange;
}

#navigation img{
    margin-left: 10%;
    margin-top: 15px;
    width: 110px;
}

#navigation ul{
    padding-top: 20px;
    padding-left: 30%;
}

#navigation ul li {
    display: inline-block;
}

.option{
    color: #35347B;
    margin: 20px;
    text-decoration: none;
    text-shadow: rgb(212, 212, 212) 0.5px 2px 2px;
}

.option:hover{
    text-shadow: rgb(143, 143, 143) 0.5px 2px 10px;;
}

/* Media query para tablets */
@media (min-width: 768px) and (max-width: 1023px) {
    #navigation {
        width: 100hw;
    }
    
    #navigation ul {
        padding-top: 5px;
        padding-left: 30%;
    }

    #navigation ul li {
        display: block;
        justify-content: center;
    }

    #navigation img {
        visibility: hidden;
        width: 50px;
    }
    
}


/* Media query para smartphones */
@media (min-width: 391px) and (max-width: 767px) {
    #navigation {
        width: 100hw;
    }
    
    #navigation ul {
        padding-top: 5px;
        padding-left: 35%;
    }

    #navigation ul li {
        display: block;
        justify-content: center;
    }

    #navigation img {
        visibility: hidden;
        width: 1px;
    }


}

@media (max-width: 391px){
    #navigation {
        width: 100hw;
    }
    
    #navigation ul {
        padding-top: 5px;
        padding-left: 30%;
    }

    #navigation ul li {
        display: block;
        justify-content: center;
    }

    #navigation img {
        visibility: hidden;
        width: 1px;
    }

}