.galeria {
    display: grid;
    grid-template-columns: repeat(5, 2fr);
    grid-auto-rows: 300px;
    padding: 20px;
    grid-gap: 10px ;
}

.titgaleria {
    margin-top: 40px;
    font-size: 2.5rem;
}

.imagen {
    width: 98%;
    height: 100%;
    object-fit: cover;
}

.item:nth-child(1) {
    grid-column-start: span 2;
}

.item:nth-child(2) {
    grid-column-start: span 2;
}

.item:nth-child(6) {
    grid-column-start: span 3;
}

.item:nth-child(7) {
    grid-column-start: span 2;
}

.item:nth-child(11) {
    grid-column-start: span 2;
}

.item:nth-child(13) {
    grid-column-start: span 2;
}

.item:nth-child(17) {
    grid-column-start: span 2;
}

.item:nth-child(18) {
    grid-column-start: span 2;
}

.item:nth-child(20) {
    grid-column-start: span 2;
}

/* Media query para tablets */
@media (min-width: 768px) and (max-width: 1023px) {
    .galeria {
        display: block;
    }
    
}


/* Media query para smartphones */
@media (min-width: 391px) and (max-width: 767px) {
    .galeria {
        display: block;
    }
}

@media (max-width: 391px){
    .galeria {
        display: block;
    }

}