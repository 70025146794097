.contacto {
    padding-top: 50px;
    height: 900px;
}

.contacto h2 {
    font-size: 2rem;
}

.contacto input {
    height: 25px;
    width: 500px;
    margin: 10px;
    border: #35347b solid 2px;
    border-radius: 10px;
}

.contacto textarea {
    width: 500px;
    border: #35347b solid 2px;
    border-radius: 10px;
}

.contacto button {
    margin-top: 10px;
    width: 510px;
    height: 40px;
    background-color: #35347b ;
    color: aliceblue;
    border-radius: 10px;
}

/* Media query para tablets */
@media (min-width: 768px) and (max-width: 1023px) {
    .contacto input {
        width: 90%;
    }
    
    .contacto textarea {
        width: 90%;
    }
    
    .contacto button {
        width: 90%;
    }
    
}


/* Media query para smartphones */
@media (min-width: 391px) and (max-width: 767px) {
    .contacto input {
        width: 90%;
    }
    
    .contacto textarea {
        width: 90%;
    }
    
    .contacto button {
        width: 90%;
    }


}

@media (max-width: 391px){
    .contacto input {
        width: 90%;
    }
    
    .contacto textarea {
        width: 90%;
    }
    
    .contacto button {
        width: 90%;
    }
}