.desarrollo {
    height: auto;
    width: 100vw;
    display: inline-block;
    font-size: 1.4rem;
}

.desarrollo section {
    padding: 5%;
    color: rgb(19, 18, 18);
    display: inline-block;
}


/* Media query para tablets */
@media (min-width: 768px) and (max-width: 1023px) {
    .desarrollo {
        width: 100%;
        font-size: 1.5rem;
    }
}


/* Media query para smartphones */
@media (min-width: 391) and (max-width: 767px) {
    .desarrollo {
        width: 100%;
        font-size: 1.0rem;
    }

}

@media (max-width: 390) {
    .desarrollo {
        width: 100%;
        font-size: 0.5rem;
    }

}